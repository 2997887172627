import React, {useState} from "react";
import {
  AboutBottom,
  AboutContainer,
  AboutDesc,
  AboutMain,
  AboutMainImg,
  AboutMainLeft,
  AboutMainRight,
  AboutRightDesc,
  AboutRightLi,
  AboutRightNav,
  AboutRightTitle,
  AboutRightUl,
  AboutSection,
  AboutTitle,
  AboutTop,
  AboutTopDesc,
  AboutTopIcons,
  AboutTopNav,
  AboutTopNavDesc,
  AboutTopNavTitle,
} from "./about.style";
import about from "../../assets/images/about.png";
import mediumIcon from "../../assets/images/mediumIcon.jpeg";
import verification from "../../assets/images/verification.png";
import encryption from "../../assets/images/encryption.png";

const About = () => {
  const [active, setActive] = useState(1);
  return (
    <AboutSection>
      <AboutContainer>
        <AboutTop>
          <AboutTopNav>
            <AboutMainImg
              style={{width: 80, height: 80, borderRadius: 20}}
              src={mediumIcon}
            />
            <AboutTopNavDesc>
              <AboutTopNavTitle>Project Delivery</AboutTopNavTitle>
              <AboutTopDesc>
                We deliver top notch optimized app for our clients and have high
                profiled instructors for learners
              </AboutTopDesc>
            </AboutTopNavDesc>
          </AboutTopNav>
          <AboutTopNav>
            <AboutMainImg
              style={{width: 200, height: 130}}
              src={verification}
            />
            <AboutTopNavDesc>
              <AboutTopNavTitle>Authentication</AboutTopNavTitle>
              <AboutTopDesc>
                We implement all kinds of In-App authentication
              </AboutTopDesc>
            </AboutTopNavDesc>
          </AboutTopNav>
          <AboutTopNav>
            <AboutTopIcons style={{width: 200, height: 130}} src={encryption} />
            <AboutTopNavDesc>
              <AboutTopNavTitle>Payments</AboutTopNavTitle>
              <AboutTopDesc>
                Our Payment System Is safe and secure. you have absolutely
                nothing to worry about
              </AboutTopDesc>
            </AboutTopNavDesc>
          </AboutTopNav>
        </AboutTop>
        <AboutBottom>
          <h1>
            MASKU
            <AboutTitle> TECHNOLOGY</AboutTitle>
          </h1>
          <AboutDesc>
            Eliqsar App is the only dating platform specifically developed for
            Nigerians to get connected to their potential partners.
          </AboutDesc>
          <AboutMain>
            <AboutMainLeft>
              <AboutMainImg src={about} />
            </AboutMainLeft>
            <AboutMainRight>
              <AboutRightTitle>ABOUT MASKU TECH</AboutRightTitle>
              <AboutRightDesc>
                Welcome to Masku Technology Limited, a cutting-edge software
                company dedicated to crafting innovative solutions that
                transform challenges into opportunities. Our mission is to
                empower individuals, businesses, and communities by developing
                problem-solving applications and delivering top-notch app
                development services
              </AboutRightDesc>
              <AboutRightNav></AboutRightNav>

              <AboutRightNav>
                <AboutRightUl>
                  <AboutRightLi
                    active={active === 1}
                    onClick={() => setActive(1)}
                  >
                    About MaskuTech
                  </AboutRightLi>
                  |
                  <AboutRightLi
                    active={active === 2}
                    onClick={() => setActive(2)}
                  >
                    Our Mission
                  </AboutRightLi>
                  |
                  <AboutRightLi
                    active={active === 3}
                    onClick={() => setActive(3)}
                  >
                    Our Guarantee
                  </AboutRightLi>
                </AboutRightUl>
              </AboutRightNav>
              {active === 1 ? (
                <AboutRightDesc>
                  Masku Technology is a registered software company in Nigeria
                  with the registration number RC6992098
                </AboutRightDesc>
              ) : active === 2 ? (
                <AboutRightDesc>
                  Our mission is to empower individuals, businesses, and
                  communities by developing problem-solving applications and
                  delivering top-notch app development services.
                </AboutRightDesc>
              ) : (
                <AboutRightDesc>
                  We guarantee a top notch services
                </AboutRightDesc>
              )}
            </AboutMainRight>
          </AboutMain>
        </AboutBottom>
      </AboutContainer>
    </AboutSection>
  );
};

export default About;
