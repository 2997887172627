import React, {useState} from "react";
import {
  LogoImg,
  MobileMenu,
  MobileMenuBox,
  MobileMenuExit,
  Nav,
  NavButtons,
  NavLogo,
  NavLogo2,
  NavMenu,
  NavMenuItems,
  SignIn,
  StatDesc,
  StatTitle,
  TopNav,
  TopStat,
  TopStatUl,
} from "./navbar.style";
import logo from "../../assets/images/AppLogo.jpg";

const Navbar = ({text}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Nav>
        <TopNav>
          {!text && (
            <>
              {" "}
              <NavLogo>
                <LogoImg src={logo} />
                <p
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "whitesmoke",
                    letterSpacing: 0.8,
                  }}
                >
                  Masku Tech
                </p>
              </NavLogo>
              <TopStatUl>
                <TopStat>
                  <StatTitle> Total Students</StatTitle>
                  <StatDesc>2035 Students</StatDesc>
                </TopStat>
                <TopStat>
                  <StatTitle> Total Clients Projects</StatTitle>
                  <StatDesc> 53 Projects</StatDesc>
                </TopStat>
                <TopStat>
                  <StatTitle> Our Apps</StatTitle>
                  <StatDesc> 3 </StatDesc>
                </TopStat>
              </TopStatUl>
            </>
          )}
        </TopNav>
      </Nav>
      <NavMenu open={open}>
        <NavMenuItems open={open}>
          <NavButtons>
            <SignIn>Home</SignIn>
            <SignIn>About</SignIn>
            <SignIn>Services</SignIn>
            <SignIn>Courses</SignIn>
            <SignIn>Apps</SignIn>
            <SignIn>Contact Us</SignIn>
          </NavButtons>
        </NavMenuItems>
      </NavMenu>
      <NavLogo2>
        <LogoImg src={logo} />
      </NavLogo2>
      <MobileMenuBox>
        {open ? (
          <MobileMenuExit onClick={() => setOpen(false)} />
        ) : (
          <MobileMenu onClick={() => setOpen(true)} />
        )}
      </MobileMenuBox>
    </>
  );
};

export default Navbar;
